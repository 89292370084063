import React, { useState,useEffect } from "react";
import { IconComponent, View, Text,Image,StyleSheet,ScrollView,TouchableOpacity } from "react-native";
import { WebView } from 'react-native-webview';
import { getChannelByChannelId } from '../trpc/memo';
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import { CommonActions } from "@react-navigation/native";

import {IconButton,Snackbar } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import EditMemo from "./EditMemo";
import CommentModal from "./CommentModal";
import ParticipationModal from "./ParticipationModal";
import  GeneralHelper  from './utils/GeneralHelper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Button from '@mui/material/Button';
import PopUpDialog from './PopUpDialog';
import SocialSharingModal from './SocialSharingModal';
import { updateAcceptanceList } from '../trpc/participation';
import MemberHelper from './utils/MemberHelper';



export default function MemoDetailController({ route, navigation }) {

  const memoID = route.params.itemID;
  const routeMemo = route.params.item;
  const [ is_edit, setIsEdit ] = useState(false);
  const [ isSignUp, setIsSignUp ] = useState(false);
  const [ eventFull, setEventFull ] = useState(false);
  const [ popupMsg, setPopUpMsg ] = useState("");
  const [ popUpVisible, setPopUpVisible ] = useState(false);
  const [ snackBarVisible, setSnackBarVisible ] = useState(true);
  const [ returnData, setReturnData ] = React.useState([{}]);
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ socialSharingVisible, setSocialSharingVisible ] = useState(false);
  const [ isRefresh, setIsRefresh ] = useState(false);
  const [ participationListVisible, setParticipationListVisible ] = useState(false);
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("uid");
  const [isMember, setIsMember] = useState(false);
  const [imagesUrl, setImagesUrl] = useState([]);

  const handleChatNav = () =>{

      navigation.navigate(
        "ChatRoomScreen", 
          {
            memoID,
          }
      );

  }

  // Use useFocusEffect to call MemberHelper.checkMemberStatus when the component gains focus
  useFocusEffect(
    React.useCallback(() => {
      console.log("Component gained focus");
      console.log(MemberHelper.checkMemberStatus());

      if(MemberHelper.checkMemberStatus()){
        setIsMember(true);
      }else{
        setIsMember(false);
      }
    }, [])
  );

  const handleLikeBtn = () =>{

    console.log("handleLikeBtn");
    setPopUpMsg("To Do");
    setPopUpVisible(true);

  }
  const handleCommentBtn = () =>{
    console.log("handleCommentBtn");
    setModalVisible(true);
  }

  const handleShareBtn = () =>{

    setSocialSharingVisible(true);

  }
  const closeModal = () => {
    setModalVisible(false);
  };

  const parseAndStyleHTML = (htmlContent) => {
    // Replace the img tag with a custom class. Refer to /assets/css/styles.css
    const styledHTML = htmlContent.replace(/<img/g, '<img class="memoDetail-Custom-img"');
    return styledHTML;
  }

  const handleSocialCallback = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        console.log('Link copied to clipboard');
        setSocialSharingVisible(false);
        setPopUpMsg("Link copied to clipboard!");
        setPopUpVisible(true);
      })
      .catch((error) => {
        console.error('Unable to copy link to clipboard', error);
      });
  };

  //For web
  const handlebackBtn = () => {
      location.href="../";
  };

  const shareContent = async () => {
    try {
      const options = {
        message: 'Check out this awesome content!',
        title: 'My App',
        url: 'https://example.com', // URL you want to share
        imageUrl: 'https://memooo.s3.us-west-2.amazonaws.com/usr_306df373-8ddb-456b-becd-04165593d119/2023-12-18/desktop_5ba7b446-ca1e-4caf-b04f-45bb82a47b55.webp', // Image URL
      };

      await Share.open(options);
    } catch (error) {
      console.error('Error sharing:', error.message);
    }
  };

  const handleJoinClick = (participationListId) =>{

    let bodyobj = {
        "participationListId": participationListId,
        "action": "ACCEPT"
      };

    if(returnData[0].topics[0].participationList.acceptanceList.length < returnData[0].topics[0].participationList.maxCapacity){

      updateAcceptanceList(bodyobj,token).then(result => {
        console.log(result);

        setPopUpMsg("You are now in the group");
        setSnackBarVisible(false);
        setIsSignUp(true);
        setParticipationListVisible(true);

        //setIsSubmit(false);

      }).catch(error => {
        console.log(error);
        setPopUpMsg("System Error");
        setPopUpVisible(true);
        setIsSignUp(false);
        //setIsSubmit(false);
      });
    }else{
        setPopUpMsg("Maximum Capacity achieved");
        setPopUpVisible(true);
        setIsSignUp(false);
    }

  };

  const handleJoinNowPopUpClose = () => {
    setPopUpVisible(false);
  };

  const showParticipationList = () => {
    setParticipationListVisible(true);
  };

  const closeParticipationList = () => {
    setParticipationListVisible(false);
  };

  const handleSnackbarDismiss = () =>{
    console.log("test");
  };

    useEffect(() => {
        localStorage.getItem("is_edit") != null ? setIsEdit(localStorage.getItem("is_edit")) : setIsEdit(route.params.is_edit);


        if(memoID&& !is_edit){
            getChannelByChannelId(memoID,token).then(result =>  {
              setReturnData(result);
              console.log(result);

              let acceptanceList = result[0].topics[0].participationList.acceptanceList;

              let filteredAcceptanceList = acceptanceList.filter((item, index) => {
                console.log("item.user.id", item.user.id +":"+ user_id);
                if (item.user.id == user_id) {
                  setIsSignUp(true);
                  return;
                } 
              });
              console.log("IsSignUp", isSignUp);

               checkEventFull(result); 
            })
            .catch(error => {
                    console.log(error);
            });

            navigation.setOptions({
              headerLeft: () => (
                <IconButton icon={props => <Icon name="arrow-left" {...props} />} onPress={handlebackBtn}  />
              ),
            });

        }

    }, []);

    const checkEventFull = (result) =>{
        
        if(result[0].topics[0].participationList.acceptanceList.length >= result[0].topics[0].participationList.maxCapacity){
          setEventFull(true);
        }else{
          setEventFull(false);
        }
    }


//'Home', { screen: 'mypost' }
    const handleTagClick = (tag) => {
          navigation.navigate('Home',{
            screen: 'home',
            params: { 
              tag: tag,
              ran:Math.floor(Math.random() * 100) 
            },
            merge: true,
          });
    }

    return (
          <View style={styles.container}>

            {returnData[0].name && (
              <Text style={styles.memoTitle}>{returnData[0].name}</Text>
            )}

            <ScrollView style={styles.memoDetail}>
              {
                returnData && returnData[0].topics && returnData[0].topics[0] ? (
                  <div 
                    style={styles.memoDescription}
                    dangerouslySetInnerHTML={{ __html: parseAndStyleHTML(GeneralHelper.deviceToPlatForm(returnData[0].topics[0].description)) }}
                  />
                ) : null
              }
            </ScrollView>
              {
                returnData && returnData[0].topics && returnData[0].topics[0] ? (
                <Text style={styles.tagText}>
                  {returnData[0].topics[0].tags.map((tag, index) => (
                    <React.Fragment key={index}>
                      
                      <Button style={styles.tagStyle} variant="contained" onClick={() => handleTagClick(tag)}>

                        <Ionicons name="pricetag-outline" />{tag.length > 20 ? tag.substring(0, 20) + '...' : tag + ' '}
                      </Button>

                    </React.Fragment>
                  ))}
                </Text>
                ) : null
              }
              <View style={styles.functionSection}>
                <IconButton icon={props => <Icon name="heart-outline" {...props} />} onPress={handleLikeBtn}  />
                <IconButton icon={props => <Icon name="comment-outline" {...props} />} onPress={handleCommentBtn}  />
                <IconButton icon={props => <Icon name="share-outline" {...props} />} onPress={handleShareBtn}  />
            {
              returnData[0].category!=="INDOOR"&&returnData[0].category!=="OUTDOOR"||!isMember? (
                <Text style={styles.joinStyleText}></Text>
              ):(

                returnData[0]&&eventFull?(
                        <Button style={styles.joinedStyle} variant="outlined">
                            <Ionicons style={styles.joinStyleIcon} name="person-add-sharp" /> 
                            <Text style={styles.smallText}> (Event is Full!)</Text>
                        </Button>

                ):(

                    returnData[0]&&isSignUp ? (
                      
                        <Button style={styles.joinedStyle} variant="outlined" onClick={() => showParticipationList(returnData[0].topics[0].participationList.id)}>
                            <Ionicons style={styles.joinStyleIcon} name="person-add-sharp" /> 
                            <Text style={styles.joinStyleText}>Signed</Text>
                            <Text style={styles.smallText}> (Click here to show other participant)</Text>
                        </Button>
                    ) : (

                        <Button style={styles.joinStyle} variant="contained" onClick={() => handleJoinClick(returnData[0].topics[0].participationList.id)}>
                            <Ionicons style={styles.joinStyleIcon} name="person-add-sharp" /> <Text style={styles.joinStyleText}>Join Now</Text>
                        </Button>
                    )
                )
              )
            }
              </View>
            {
                returnData && returnData[0].topics && returnData[0].topics[0] ? (
                <CommentModal visible={modalVisible} onClose={closeModal} data={returnData} />
                ) : null
            }

            <ParticipationModal 
                visible={participationListVisible} 
                onClose={closeParticipationList} 
                topicId={memoID} 
                setIsSignUp={setIsSignUp}
                setParticipationListVisible={setParticipationListVisible}
                setPopUpMsg={setPopUpMsg}
                setPopUpVisible={setPopUpVisible}
            />

            {/**Join Now Pop Up**/}
            <PopUpDialog
              message={popupMsg}
              visible={popUpVisible}
              onClose={handleJoinNowPopUpClose}
            />
            <SocialSharingModal
              message={'Sharing'}
              visible={socialSharingVisible}
              callback={handleSocialCallback}
            />
          </View>         
    );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    //padding: 5,
  },
  joinStyle:{
    backgroundColor:"#5984c9",
    margin: 5
  },
  joinedStyle:{
    margin: 5
  },
  joinStyleIcon:{
    marginRight:5
  },
  joinStyleText:{

  },
  tagStyle:{
    backgroundColor:"#000",
    color:"#fff",
    margin: 5,   
  },
  tagText:{
    padding:5
  },
  memoTitle: {
    fontSize: 20,
    fontWeight: "bold",
    width:"90%",
    padding:5,
    textAlign: "center",
  },
  memoDetail: {
    flex: 1,
    padding: 10,
  },
  memoDescription: {
    width: "100%",
    MaxWidth: "720px",
    //textAlign:"center"
  },
  chatBtn:{
    paddingTop:10
  },
  functionSection:{
    display: "flex",
    flexDirection: "row",
  },
  functionBtn:{

  },
  smallText:{
    fontSize:10
  }
});