import axios from "axios";


/*
{
    "topicId": "topc_57dd8295-7da4-4b5c-941b-cbe05f6ea120",
    "maxCapacity": 20
}
*/

//Create Participation List
export const createTopicParticipationList = async (obj,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = obj;
  const config = {
    method: "POST",
    url: `${
      process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
    }/createTopicParticipationList`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
    data: { ...payload},

  };
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("createTopicParticipationList", error);
  }
};


/*
  {
    "id": "part_65bc8a69-48cb-4073-bfd8-c64d8dba8866",
    "topicId": "topc_57dd8295-7da4-4b5c-941b-cbe05f6ea120",
    "maxCapacity": 45
  }
*/

// Update Participation List Setting
export const updateTopicParticipationList = async (obj,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = obj;
  const config = {
    method: "POST",
    url: `${
      process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
    }/updateTopicParticipationList`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
    data: { ...payload},

  };
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("updateTopicParticipationList", error);
  }
};

/*
  {
    "participationListId": "part_65bc8a69-48cb-4073-bfd8-c64d8dba8866",
    "action": "ACCEPT"
  }
*/

// Update Acceptance List (action: ACCEPT | WITHDRAW)
export const updateAcceptanceList = async (obj,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = obj;
  const config = {
    method: "POST",
    url: `${
      process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
    }/updateAcceptanceList`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
    data: { ...payload},

  };
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("updateAcceptanceList", error);
  }
};




