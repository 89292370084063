import React from "react";
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {Platform} from 'react-native';

const GeneralHelper = {
  debug: (log) => {
      console.log(log);
      return true;
  },
  deviceToPlatForm: (url)=>{
    let platform = "";

    if (Platform.OS === 'ios') {
      platform = "mobile_";
    } else if (Platform.OS === 'android') {
      platform = "mobile_";
    } else if (Platform.OS === 'web') {
      platform = "desktop_";
    } else {
      platform = "desktop_";
    }
    return url.replace("device_",platform);
  },
  platformToDevice:(url)=>{

    let platform = "";

    if (Platform.OS === 'ios') {
      platform = "mobile_";
    } else if (Platform.OS === 'android') {
      platform = "mobile_";
    } else if (Platform.OS === 'web') {
      platform = "desktop_";
    } else {
      platform = "desktop_";
    }

    return url.replace(platform,"device_");

  },
  formatDateTime : (dateTimeString)=> {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    const isToday = dateTime.toDateString() === now.toDateString();
    const isYesterday = dateTime.toDateString() === yesterday.toDateString();

    if (isToday) {
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `Today at ${formattedHours}:${formattedMinutes}${amOrPm}`;
    } else if (isYesterday) {
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `Yesterday at ${formattedHours}:${formattedMinutes}${amOrPm}`;
    } else {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate = dateTime.toLocaleDateString(undefined, options);
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedDate} at ${formattedHours}:${formattedMinutes}${amOrPm}`;
    }
  },
  formatTimeAgo(timestamp) {
    const currentDate = new Date();
    const pastDate = new Date(timestamp);

    const timeDifference = currentDate - pastDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days + 'd';
    } else if (hours > 0) {
      return hours + 'h';
    } else if (minutes > 0) {
      return minutes + 'm';
    } else {
      return seconds + 's';
    }
  },
  imageExtractor : (htmlContent) => {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML content string into a DOM document
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all image elements in the DOM document
    const imageElements = doc.querySelectorAll('img');

    // Convert NodeList to an array and extract the 'src' attribute of each image
    const extractedImages = Array.from(imageElements).map((imgElement) =>
      imgElement.getAttribute('src')
    );

    return extractedImages;
  },
  shortenText:(content)=>{

    let returnText = (content.length > 20 )? content.substring(0, 20) + '...' : content + ' ';

    return returnText;
  }


}

export default GeneralHelper;
