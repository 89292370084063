import axios from "axios";


/*
{
   "searchType":"match",
   "term":"吹水",
   "category":"<optional>",
   "from":"<optional>",
   "maxResults":"<optional>",
   "filterType":"<optional>"
}
*/

//search API
export const searchChannel = async (obj,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = obj;
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/searchChannel?input=${encodeURIComponent(JSON.stringify(payload))}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("searchChannel", error);
  }
};





