import React, { useState,useEffect } from "react";
import { 
  Button, 
  View, 
  ScrollView,
  SafeAreaView,
  Text,
  Image,
  FlatList,
  StyleSheet } from "react-native";
import GetMemoList from "../components/GetMemoList";
import MemberHelper from './utils/MemberHelper';
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import PopUpDialog from './PopUpDialog';

const mode  = "mypost"; 


export default function MyPostScreen({ route, navigation }) {

  const user_id = localStorage.getItem("uid");
  const [popupMsg, setPopUpMsg] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isRefresh,setIsRefresh] = useState(false);

  useEffect(()=>{
    //navigation.navigate('Home', { screen: 'MyPostScreen', });

  });

  // Use useFocusEffect to call MemberHelper.checkMemberStatus when the component gains focus
  useFocusEffect(
    React.useCallback(() => {
      console.log("Component gained focus");
      console.log(MemberHelper.checkMemberStatus());
      setIsRefresh((prev) => !prev); 

      if(MemberHelper.checkMemberStatus()){
        setIsMember(true);
      }else{
        setPopUpMsg("You are not member");
        setIsMember(false);
        setModalVisible(true);
      }
    }, [navigation]) // The dependencies array should be empty if you don't have any external dependencies
  );

  // Function to show the modal
  const showModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
    if(!isMember){
      navigation.navigate("profile");
    }
  };


  return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.scrollView}>
        {
            (isMember)?(
              <GetMemoList user_id={user_id} mode={mode} isRefresh={isRefresh}/>
            ):(null)
          }
            <PopUpDialog
              message={popupMsg}
              visible={modalVisible}
              onClose={closeModal}
            />
        </ScrollView>
      </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#55575e',
    alignItems: 'left',
    justifyContent: 'left',
  },
  scrollView:{
    width:'100%'
  }
});