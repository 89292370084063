import React, { useMemo, useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  View,
  Image,
  useColorScheme,
  TouchableHighlight,
  SafeAreaView,
  StatusBar,
} from 'react-native';

import MasonryList from '@react-native-seoul/masonry-list';
import { useNavigation } from '@react-navigation/native';
import  GeneralHelper  from './utils/GeneralHelper';
import Ionicons from 'react-native-vector-icons/Ionicons';

interface Memo {
  id: string;
  name: string;
  category: string;
}

export default function MemoCard({
  navigation,
  item,
  style,
  memoColor,
  is_edit,
}) {
  const randomBool = useMemo(() => Math.random() < 0.5, []);
  const memoColorArray = [
    '#f9ec8e',
    '#f98eac',
    '#7cd8ff',
    '#beeb97',
    '#ffa371',
    '#d0abfa',
  ];
  const [ishover, setHover] = useState(false);
  const itemID = item.id;

  const handleMemoPress = () => {

    localStorage.setItem("is_edit",is_edit);

    if(is_edit){

      let memoID = itemID;
      navigation.navigate('EditMemo', {
        is_edit,
        memoID,
      });
    }else{
      navigation.navigate('MemoDetailController', {
        itemID,
        item,
        is_edit,
      });
    }
  };

  // Initialize imagesUrl as a state variable with an empty array
  const [imagesUrl, setImagesUrl] = useState([]);

  const imageExtractor = (htmlContent) => {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML content string into a DOM document
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all image elements in the DOM document
    const imageElements = doc.querySelectorAll('img');

    // Convert NodeList to an array and extract the 'src' attribute of each image
    const extractedImages = Array.from(imageElements).map((imgElement) =>
      imgElement.getAttribute('src')
    );

    // Update the state with the extracted images
    setImagesUrl(extractedImages);
  };

  const textExtractor = (htmlContent, englishWordLimit = 10, chineseWordLimit = 10) => {

    const displayLength = 50;
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML content string into a DOM document
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Get the text content of the entire document
    const textContent = doc.documentElement.textContent;


    const displayContent = textContent.length>displayLength? textContent.substring(0, displayLength) + '...':textContent;

    return displayContent;
  };



  useEffect(() => {
    // Call the imageExtractor function when the component mounts
    imageExtractor(item.topics[0].description);

  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <TouchableHighlight
      style={styles.touchStyle}
      activeOpacity={0.6}
      underlayColor="#fff"
      onPress={handleMemoPress}
    >
      <View
        key={item.id}
        style={[
          ishover ? styles.memoCardHover : styles.memoCard,
          style,
          { backgroundColor: item.backgroundColor },
        ]}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Text style={styles.memoTitle}>{item.name}</Text><Text style={styles.memoCat}>{item.category}</Text>
        { imagesUrl.length>0 ? ( // Check if imagesUrl[0] is not an empty string
          <Image
            source={{ uri: GeneralHelper.deviceToPlatForm(imagesUrl[0]?.trim()) ?? '' }}
            style={[
              {
                height: randomBool ? 150 : 200,
              },
              styles.memoImage,
            ]}
            resizeMode="cover"
          />
        ) : (
          null // Render nothing if imagesUrl[0] is an empty string
        )}

        {textExtractor(item.topics[0].description)!="" &&
          <Text style={styles.memoDesc}>
              {textExtractor(item.topics[0].description)}
          </Text>
        }

        {item.topics[0].tags.length>0 &&
          <Text style={styles.tagText}>
            {item.topics[0].tags.map((tag, index) => (
              <React.Fragment key={index}>
                <Ionicons name="pricetag-outline" />
                {tag.length > 20 ? tag.substring(0, 20) + '...' : tag + ' '}
              </React.Fragment>
            ))}
          </Text>
        }
          <Text style={styles.username} >
            {item.owner.name}
          </Text>
          <Text style={styles.dateTime} >
            {GeneralHelper.formatDateTime(item.updatedAt)}
          </Text>
      </View>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  memoCard: {
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    //opacity: 1,
    boxShadow: '2px 2px 5px black',
  },
  touchStyle:{
    color:"red"
  },
  dateTime:{
    padding:10,
    alignSelf:"end",
    fontSize:10,
    color:"#605a5a",
  },
  username:{
    paddingLeft:10,
    fontSize:10,
    color:"#605a5a",
  },
  memoCardHover: {
    marginTop: 12,
    marginBottom: 10,
    borderRadius: 10,
    //opacity: 0.5,
    boxShadow: '2px 2px 5px black',
  },
  memoTitle: {
    marginTop: 8,
    color: '#000',
    fontWeight: 'bold',
    padding: 10,
    fontSize:16
  },
  memoDesc: {
    color: '#000',
    padding: 10,
    paddingTop:5,
    fontSize: 13,
  },
  tagText:{
    padding:10,
    fontSize:10,
    color:"#605a5a",
  },
  memoImage: {
    alignSelf: 'stretch',
    padding: 10,
  },
  MemoOverlay: {},
  memoCat:{
    paddingLeft:10,
    fontSize:10,
    color:"#605a5a",
  }
});
