import { StatusBar } from "expo-status-bar";
import React, { useState,useEffect,useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Pressable,
  TouchableOpacity,
} from "react-native";

import { GoogleLogin,GoogleOAuthProvider,useGoogleLogin,googleLogout } from '@react-oauth/google';
import axios from 'axios';
import { socialSignIn, getUser,updateUserInfo } from '../trpc/users'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useDropzone } from 'react-dropzone';
import  GeneralHelper  from './utils/GeneralHelper';

export default function LoginScreen() {

    const [ googleUser, setGoogleUser ] = useState(null);
    const [ userProfile, setUserProfile ] = useState(null);
    const [ jsonProfile, setJsonProfile ] = useState(null);
    const [displayName, setDisplayName] = useState('');
    const [displayProfilePic, setDisplayProfilePic] = useState('');
    const token = localStorage.getItem("token");
    const uid = localStorage.getItem("uid");
    const [editModeName, setEditModeName] = useState(false);
    const [editModeProfilePic, setEditModeProfilePic] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [files, setFiles] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
          setGoogleUser(codeResponse); 
          console.log('codeResponse: ', codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const onDrop = useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: {
        'image/*': []
      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });
    useEffect(() => {
      LoadUserData();

              if (googleUser) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
                      headers: {
                          Authorization: `Bearer ${googleUser.access_token}`,
                          Accept: 'application/json'
                      }
                  })
                  .then(async (res) => {
                      //console.log('googleapis/userinfo',res.data);

                      //Social Signin
                      const savedUserProfile = localStorage.getItem("userProfile");
                      let result = await socialSignIn(res.data);
                      //console.log("socialSignIn",result);

                      localStorage.setItem("uid",result.id);
                      localStorage.setItem("userProfile",JSON.stringify(result));

                      LoadUserData();

                      // Save Token
                      localStorage.setItem("token",result.token);

                  })
                  .catch((err) => console.log(err));
              }
          },
          [ googleUser ]
      );

    const LoadUserData=()=>{

        const savedUserProfile = localStorage.getItem("userProfile");
        setJsonProfile(JSON.parse(savedUserProfile));
        //setDisplayName(jsonProfile.name);
        console.log('LoadUserData',jsonProfile);

    }

      // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        console.log("logout");
        googleLogout();
        setUserProfile(null);
        setJsonProfile(null);
        setGoogleUser(null);
        localStorage.setItem("userProfile",null);
        localStorage.setItem("token",null);
        localStorage.setItem("uid",null);
    };

  const handleKeyPress = (event) => {
    if (event.nativeEvent.key === 'Enter') {

    }
  };

  const handleUploadImage = async () => {

    try {
      const formData = new FormData();
      formData.append('images', files[0]);
      console.log(files);

        setIsUploading(true);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: process.env.BASE_IMAGE_UPLOAD_URL,
          headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'multipart/form-data',
          },
          data : formData
        };

        axios.request(config)
        .then((response) => {
          console.log(response);
          setUploadedImageUrl(response.data.data[0]);
          updateProfileUrl(response.data.data[0]);
          setIsUploading(false);

        })
        .catch((error) => {
          setIsUploading(false);
          console.log(error);
        });  

    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

    const handleEditProfilePic = () =>{
      //editModeProfilePic
      if (editModeProfilePic) {
        // If in edit mode, save changes
        if(!isUploading){
          handleUploadImage();
        }
      } else {
        // If not in edit mode, toggle edit mode
        setDisplayProfilePic(jsonProfile.profileURL);
        setEditModeProfilePic(!editModeProfilePic);
      }

    };

    const editUserName = () =>{

      if (editModeName) {
        // If in edit mode, save changes
        saveUserName();
      } else {
        // If not in edit mode, toggle edit mode
        setDisplayName(jsonProfile.name);
        setEditModeName(!editModeName);
      }

    };

    const updateProfileUrl = (newPicUrl) => {

      let bodyobj = {
          "id": uid,
          "picture": newPicUrl
        };

        updateUserInfo(bodyobj,token).then(result => {
          console.log(result);
          setEditModeProfilePic(!editModeProfilePic);
          localStorage.setItem("userProfile",JSON.stringify(result));
          LoadUserData();

        }).catch(error => {
          console.log(error);
          setEditModeProfilePic(!editModeProfilePic);
        });
    }

    const saveUserName = () => {
          let bodyobj = {
              "id": uid,
              "name": displayName
            };

            updateUserInfo(bodyobj,token).then(result => {
              console.log(result);
              setEditModeName(!editModeName);
              setDisplayName(result.name);
              localStorage.setItem("userProfile",JSON.stringify(result));
              LoadUserData();

            }).catch(error => {
              console.log(error);
              setEditModeName(!editModeName);
            });
    }

 const thumbs = files.map(file => (
    <div style={styles.thumb} key={file.name}>
      <div style={styles.thumbInner}>
        <img
          src={file.preview}
          style={styles.img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));


  return (
    <View>
            {(jsonProfile==null) ? (
              <Stack spacing={2} direction="column">
                <p>Please signin to continue browsing</p>
                <Button style={styles.loginBtn} variant="contained" onClick={login}>Sign in with Google</Button>
              </Stack>

            ) : (
                <div style={styles.container}>
                  <h3>User Logged in</h3>
                  {editModeProfilePic ? (
                      <View>
                        <View {...getRootProps()} style={styles.dropZone}>
                          <input {...getInputProps()} />
                          {thumbs}
                          {isDragActive ? (
                            <Text>Drop the image here ...</Text>
                          ) : (
                            <Text>Click to select an image</Text>
                          )}
                        </View>
                      </View>
                    ) : (
                          <View style={styles.thumb}>
                            <img  style={styles.img}
                                  src={GeneralHelper.deviceToPlatForm(jsonProfile.profileURL)} 
                                  alt={GeneralHelper.deviceToPlatForm(jsonProfile.profileURL)} 
                            />
                          </View>
                        )}
                        {isUploading && <Text>Uploading...</Text>}
                        <br />
                        <Link
                          style={styles.saveBtn}
                          component="button"
                          variant="body2"
                          onClick={handleEditProfilePic}
                        >
                          {editModeProfilePic ? "Save" : "Edit Image"}
                        </Link>
                        <br/><br />
                            Name:{" "}
                            {editModeName ? (
                              <TextInput
                                style={styles.setDisplayNameInput}
                                onChangeText={setDisplayName}
                                value={displayName}
                                placeholderTextColor="#9b9a9a"
                                onKeyPress={handleKeyPress}
                              />
                            ) : (
                              <Text>{jsonProfile.name}</Text>
                            )}
                            <Link style={styles.link} component="button" variant="body2" onClick={editUserName}>
                              {editModeName ? "Save" : "Edit"}
                            </Link>
                        <p>Email Address: {jsonProfile.email}</p>
                        <Button style={styles.loginBtn} variant="contained" onClick={logOut}>Logout</Button>
                </div>
            )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    textAlign: "center",
  },
  link:{
    marginLeft:5,
    marginBottom: 4
  },
  saveBtn:{

  },
  image: {
    marginBottom: 40,
  },
  inputView: {
    backgroundColor: "#FFC0CB",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },
  TextInput: {
    height: 50,
    flex: 1,
    padding: 10,
    marginLeft: 20,
    width:'100%'
  },
  dropZone:{
    flex: 1,
    border: "1px solid #b5b5b5",
    padding: 20,
    alignSelf: "center",
    width: "50%",
    alignItems: "center"
  },
  setDisplayNameInput: {
    flex: 1,
    padding:5,
    alignItems:"center",
    marginLeft:5,
    border: "1px #979797 solid",
    borderRadius: 10,
    backgroundColor: '#fff',
    fontSize: 12, // Adjust font size
    maxWidth: '50%', // Responsive width
  },
  forgot_button: {
    height: 30,
    marginBottom: 30,
  },
  loginBtn: {
    alignSelf: "center", 
    backgroundColor:"#2196f3",
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    padding:8,
    width: 120, // Set a fixed width for square-like dimension
    height: 120, // Set a fixed height for square-like dimension
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    width: '100%', // Ensure images take the full width
    height: '100%', // Ensure images take the full height
  },

  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Maintain aspect ratio while covering the container
  },
});