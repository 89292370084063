import React, { useState,useEffect } from 'react'; 
import { View, Text, TextInput, TouchableOpacity,  
    StyleSheet } from 'react-native'; 
  

export default function TagInputComponent({returnTags,updateTags}) { 

    const [text, setText] = useState(''); 
    const [editIndex, setEditIndex] = useState(null); 
  
    const addTag = () => {
      if (text.trim() !== '') {
        // Check if the tag already exists
        const isDuplicate = returnTags.includes(text.trim());

        if (!isDuplicate) {
          if (editIndex !== null) {
            // If editing an existing tag
            const newTags = [...returnTags];
            newTags[editIndex] = text.trim();
            updateTags(newTags);
            setEditIndex(null);
          } else {
            // If adding a new tag
            updateTags([...returnTags, text.trim()]);
          }
          setText('');
        } else {
          // Handle duplicate tag (show an error, etc.)
          console.log('Error: Duplicate tag');
          // You can also set an error state and display a message to the user
        }
      }
    };

    const removeTag = (index) => { 
        const newTags = [...returnTags]; 
        newTags.splice(index, 1); 
        updateTags(newTags); 
    }; 
  
    const editTag = (index) => { 
        const tagToEdit = returnTags[index]; 
        setText(tagToEdit); 
        setEditIndex(index); 
    }; 

    return ( 
            <View style={styles.container}> 
                <View style={styles.inputContainer}> 
                    <TextInput 
                        style={styles.input} 
                        placeholder="Add a tag"
                        value={text} 
                        onChangeText={setText} 
                        onSubmitEditing={addTag} 
                    /> 
                    <TouchableOpacity onPress={addTag}  
                        style={styles.addButton}> 
                        <Text style={styles.buttonText}> 
                            {editIndex !== null ? 'Update' : 'Add'} 
                        </Text> 
                    </TouchableOpacity> 
                </View> 

                <View style={styles.tagContainer}> 
                    {returnTags.map((tag, index) => ( 
                        <View key={index}  
                            style={styles.tagWrapper}> 
                            <TouchableOpacity  
                                onPress={() => editTag(index)}  
                                style={styles.tag}> 
                                <Text style={styles.tagText}> 
                                    {tag} 
                                </Text> 
                            </TouchableOpacity> 
                            <TouchableOpacity  
                                onPress={() => removeTag(index)}  
                                style={styles.removeButton}> 
                                <Text style={styles.removeButtonText}> 
                                    X 
                                </Text> 
                            </TouchableOpacity> 
                        </View> 
                    ))} 
                </View> 

            </View> 
        ); 
}; 

const styles = StyleSheet.create({ 
    appContainer: { 
        flex: 1, 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#F5F5F5', 
    }, 
    container: { 
        width: '80%', 
        paddingLeft:0,
        paddingTop:15,
        paddingRight:15,
    }, 
    tagContainer: { 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        marginBottom: 10, 
    }, 
    tagWrapper: { 
        flexDirection: 'row', 
        alignItems: 'center', 
        marginVertical: 5, 
        marginRight: 5, 
    }, 
    tag: { 
        backgroundColor: '#73daff', 
        borderRadius: 20, 
        paddingHorizontal: 10, 
        paddingVertical: 5, 
    }, 
    tagText: { 
        color: 'black', 
        fontWeight: 'bold', 
        fontSize: 14, 
    }, 
    removeButton: { 
        padding: 5, 
        borderRadius: 20, 
        width: 28,
        alignItems: "center",
        backgroundColor: '#E53935', 
    }, 
    removeButtonText: { 
        color: '#FFFFFF', 
        fontSize: 14, 
    }, 
    inputContainer: { 
        flexDirection: 'row', 
        alignItems: 'center', 
    }, 
    input: { 
        flex: 1, 
        height: 40, 
        color:"#999999",
        fontSize:16,
        borderColor: '#CCCCCC', 
        borderWidth: 1, 
        paddingHorizontal: 10, 
        paddingLeft: 15,
        borderRadius: 8, 
        marginRight: 10, 
        backgroundColor: '#FFFFFF', 
    }, 
    addButton: { 
        backgroundColor: '#4CAF50', 
        paddingHorizontal: 15, 
        paddingVertical: 10, 
        borderRadius: 5, 
    }, 
    buttonText: { 
        color: '#FFFFFF', 
        fontSize: 16, 
        fontWeight: 'bold', 
    }, 
}); 
