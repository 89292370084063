import React, { useState, useRef, useEffect } from "react";
import { View, Modal, Pressable, Text, StyleSheet, ScrollView, Animated } from "react-native";
import { updateAcceptanceList } from '../trpc/participation';
import { getChannelByChannelId } from '../trpc/memo';
import CommentModal from "./CommentModal";
import GeneralHelper from './utils/GeneralHelper';
import Button from '@mui/material/Button';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';

export default function ParticipationModal({ message, visible, onClose, topicId, setIsSignUp, setParticipationListVisible, setPopUpMsg, setPopUpVisible }) {

  const [modalVisible, setModalVisible] = useState(visible);
  const token = localStorage.getItem("token");
  const [memoDetailData, setMemoDetailData] = useState([{}]);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [fadeAnim] = useState(new Animated.Value(1));
  const uid = localStorage.getItem("uid");

  useEffect(() => {
    setModalVisible(visible);

    getChannel();

  }, [visible,getChannel]);

  const handleWithDraw = () => {

    let bodyobj = {
      "participationListId": memoDetailData[0].topics[0].participationList.id,
      "action": "WITHDRAW"
    };

    updateAcceptanceList(bodyobj, token).then(result => {
      console.log(result);
      setIsSignUp(false);
      getChannel();
      setPopUpMsg("You have withdrawn the participation List");
      setParticipationListVisible(false);
      setPopUpVisible(true);

    }).catch(error => {
      console.log(error);
    });

  };

  const getChannel = () => {

    getChannelByChannelId(topicId, token).then(result => {
      setMemoDetailData(result);
      console.log(result);
      setHighlightedIndex(0);

      // Fade in animation
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: false,
      }).start();

      // Fade out after 2 seconds
      setTimeout(() => {
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
        }).start(() => setHighlightedIndex(null));
      }, 2000);

    })
      .catch(error => {
        console.log(error);
      });

  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <Pressable
          style={styles.overlay}
          onPress={onClose}
        >
        </Pressable>
        <View style={styles.modalView}>
          <Pressable
            style={[styles.button, styles.buttonClose]}
            onPress={onClose}
          >
            <Text style={styles.textStyle}>X</Text>
          </Pressable>

          <Pressable
            onPress={handleWithDraw}
          >
            <Button color="error" variant="outlined" endIcon={<NotInterestedIcon />} >WithDraw</Button>
          </Pressable>
          <Text style={styles.maxText}>
            {
              memoDetailData &&
              memoDetailData[0] &&
              memoDetailData[0].topics &&
              memoDetailData[0].topics[0] &&
              memoDetailData[0].topics[0].participationList &&
              memoDetailData[0].topics[0].participationList.acceptanceList.length
            }/
            {
              memoDetailData &&
              memoDetailData[0] &&
              memoDetailData[0].topics &&
              memoDetailData[0].topics[0] &&
              memoDetailData[0].topics[0].participationList &&
              memoDetailData[0].topics[0].participationList.maxCapacity
            }
          </Text>

          <ScrollView style={styles.participationListStyle}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {memoDetailData &&
                memoDetailData[0] &&
                memoDetailData[0].topics &&
                memoDetailData[0].topics[0] &&
                memoDetailData[0].topics[0].participationList &&
                memoDetailData[0].topics[0].participationList.acceptanceList &&
                memoDetailData[0].topics[0].participationList.acceptanceList.map(
                  (item, index) => (
                    <Animated.View
                      key={index}
                      style={[
                        styles.listItem,
                        item.user.id === uid
                          ? { backgroundColor: fadeAnim.interpolate({ inputRange: [0, 1], outputRange: ['#909085', 'white'] }) }
                          : null,
                      ]}
                    >
                      <ListItemButton key={index}>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt={item.user.name} src={GeneralHelper.deviceToPlatForm(item.user.profileURL)} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.user.name}
                          />
                        </ListItem>
                      </ListItemButton>
                      <Divider variant="inset" component="li" />
                    </Animated.View>
                  )
                )}
            </List>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );

}

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: "#6e6e6e85",
    height: "100%",
    justifyContent: "end"
  },
  highlightedItem: {
    backgroundColor: "#909085",
  },
  overlay: {
    height: "100%",
    opacity: 0.1,
    elevation: 2,
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: "80%",
  },
  button: {
    borderRadius: 20,
    padding: 5,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#000",
    alignSelf: "end",
    width: 27,
  },
  withDrawButton: {
    backgroundColor: "#000",
    elevation: 3,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  WithDrawtextStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    padding: 5
  },
  modalText: {
    textAlign: "center",
  },
  textArea: {
    height: "3em",
  },
  participationListStyle: {
    padding: 5,
    width: "100%"
  },
  maxText: {
    alignSelf: "end",
    paddingRight: 10,
  }
});
