import { StatusBar } from "expo-status-bar";
import React, { useState,useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
} from "react-native";

import { GoogleLogin,GoogleOAuthProvider,useGoogleLogin } from '@react-oauth/google';

import LoginScreen from "../components/LoginScreen";

export default function ProfileScreen() {

  const handleSubmit = () => {
    console.log(email,password);
  };

  return (
    <View style={styles.container}>
      <GoogleOAuthProvider clientId="93693778097-i5ucuqrpb8tvs66etq6lg1f052fdjfag.apps.googleusercontent.com">
          <React.StrictMode>
              <LoginScreen />
          </React.StrictMode>
      </GoogleOAuthProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    marginBottom: 40,
  },
  inputView: {
    backgroundColor: "#FFC0CB",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },
  TextInput: {
    height: 50,
    flex: 1,
    padding: 10,
    marginLeft: 20,
    width:'100%'
  },
  forgot_button: {
    height: 30,
    marginBottom: 30,
  },
  loginBtn: {
    width: "70%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#FF1493",
  },
});