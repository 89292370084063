import axios from "axios";

export const createChannel = async (body,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const config = {
		method: "POST",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/createChannel`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
		data: { ...body},

	};
  try {
    const { data } = await axios(config);
    console.log(data);
    return data.result.data
  } catch (error) {
    console.error("createChannel", error);
  }
};

export const listChannels = async (jwtToken,options={}) => {
  console.log('BASE_TRPC_URL: ', process.env.BASE_TRPC_URL);
  const payload = options;
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/listChannels?input=${encodeURIComponent(JSON.stringify(payload))}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("listChannels", error);
  }
};

//get channel by user id
export const listChannelsByUserId = async (jwtToken,options={}) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = options;
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/listChannels?input=${encodeURIComponent(JSON.stringify(payload))}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("listChannelsByUserId", error);
  }
};

//Get Channel By Channel ID
export const getChannelByChannelId = async (channelID,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = { "id": channelID };
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/getChannel?input=${encodeURIComponent(JSON.stringify(payload))}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("getChannelByChannelId", error);
  }
};

//Get Topic By Topic ID
export const getTopicByTopicId = async (topicID,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = { "id": topicID };
  const config = {
    method: "GET",
    url: `${
      process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
    }/getTopic?input=${encodeURIComponent(JSON.stringify(payload))}`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
  };
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("getTopicByTopicId", error);
  }
};

//update Channel
export const updateChannel = async (body,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const config = {
		method: "POST",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/updateChannel`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
		data: { ...body},

	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("updateChannel", error);
  }
};

//update topic
export const updateTopic = async (body,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const config = {
		method: "POST",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/updateTopic`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
		data: { ...body},

	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("updateTopic", error);
  }
};




