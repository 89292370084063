import React, { useRef, useState,useEffect } from "react";
import { 
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform
} from "react-native";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import TextEditor from "../components/TextEditor";
import { useNavigation,useFocusEffect,useRoute } from '@react-navigation/native';
import MemberHelper from './utils/MemberHelper';
import { Button,Stack, TextInput, IconButton,Divider } from "@react-native-material/core";
import PopUpDialog from './PopUpDialog';
import { getChannelByChannelId } from '../trpc/memo';

export default function EditMemo() {

  const navigation = useNavigation();
  const route = useRoute();
  const [returnData, setReturnData] = React.useState([{}]);
  const token = localStorage.getItem("token");
  const memoID = route.params.memoID;

    const handlebackBtn = () => {
      navigation.navigate('Home', { screen: 'mypost' });
    };

    useEffect(() => {
            console.log(route);
            console.log(memoID,token);
            getChannelByChannelId(memoID,token).then(result =>  {
              setReturnData(result);
            })
            .catch(error => {
                    console.log(error);
            });

            navigation.setOptions({
              headerLeft: () => (
                <IconButton icon={props => <Icon name="arrow-left" {...props} />} onPress={handlebackBtn}  />
              ),
            });

    }, []);

  return (
      <View style={styles.container}>
        {returnData[0].name && (
          <Text style={styles.sysMsg}>Edit Mode</Text>
        )}
        {returnData[0].name && (
          <TextEditor is_edit={true} memObj={returnData[0]} />
        )}
      </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sysMsg:{
    //alignSelf:"center",
    textAlign:"center",
    color:"#fff",
    backgroundColor:"black"
  }
});