import React, {useMemo,useState,useEffect} from 'react';
import { 
    StyleSheet, 
    Text, 
    TextInput,View,Image, 
    useColorScheme,
    Dimensions,
    ScrollView,
    TouchableHighlight,
    ActivityIndicator,
    SafeAreaView,StatusBar} from 'react-native';
import MasonryList from '@react-native-seoul/masonry-list';
import { useNavigation } from '@react-navigation/native';

const windowDimensions = Dimensions.get('window');
const screenDimensions = Dimensions.get('screen');

import MemoCard from "../components/MemoCard";

//mode:default/mypost
export default function MemoList({ loading, data, mode, imageUrl,handleEndReached }) {
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
    screen: screenDimensions,
  });

  const navigation = useNavigation();
  const numColumns = dimensions.window.width < 780 ? 2 : 6;

  mode = typeof mode === 'undefined' ? 'default' : mode;

  const config = {
    default: {
      numColumns: dimensions.window.width < 780 ? 2 : 6,
      is_edit: false,
    },
    mypost: {
      numColumns: dimensions.window.width < 780 ? 1 : 2,
      is_edit: true,
    },
  };

  const renderItem = ({ item, i }) => {
    const min = 0;
    const max = 5;
    const randColor = Math.floor(Math.random() * (max - min + 1)) + min;

    return (
      <MemoCard
        navigation={navigation}
        item={item}
        style={{ marginLeft: i % config[mode].numColumns === 0 ? 0 : 6, marginRight:6}}
        memoColor={randColor}
        is_edit={config[mode].is_edit}
        imageUrl={imageUrl}
      />
    );
  };

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      setDimensions({ window, screen });
    });
    return () => subscription?.remove();
  }, [loading]);


  return (
    <SafeAreaView style={styles.backgroundStyle}>
      <StatusBar />
      <MasonryList
        keyExtractor={(item) => item.id}
        ListHeaderComponent={<View />}
        contentContainerStyle={{
          paddingHorizontal: 10,
          alignSelf: 'stretch',
          flexGrow: 1,
        }}
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.15}
        numColumns={config[mode].numColumns}
        data={data}
        renderItem={renderItem}
      />
    </SafeAreaView>
  );
}


const styles = StyleSheet.create({
  memoCard:{
    marginTop: 12, 
    borderRadius:10,
    opacity:1,
    boxShadow: '2px 2px 5px black',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    //paddingTop: 200,
  },
  memoCardHover:{
    marginTop: 12, 
    borderRadius:10,
    opacity:0.5,
    boxShadow: '2px 2px 5px black',
  },
  memoTitle:{
    marginTop: 8,
    color: '#000',
    fontWeight: 'bold',
    padding:10,
  },
  memoDesc:{
    marginTop: 8,
    color: '#000',
    padding:10,
  },
  memoImage:{
    alignSelf: 'stretch',
    padding:10
  },
  backgroundStyle: {
    flex: 1,
    width:"100%",
  },
  MemoOverlay:{

  }
});