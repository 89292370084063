import axios from "axios";

export const createMessage = async (body,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const config = {
		method: "POST",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/createMessage`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
		data: { ...body},

	}; 
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("createMessage", error);
  }
};

//get channel by topic id
export const listMessage = async (obj,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = obj;
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/listMessage?input=${encodeURIComponent(JSON.stringify(payload))}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("listMessage", error);
  }
};

export const replyMessage = async (body,jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const config = {
    method: "POST",
    url: `${
      process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
    }/createMessage`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
    data: { ...body},

  }; 
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("replyMessage", error);
  }
};





