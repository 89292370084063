import React, {useState,useEffect} from 'react';
import { StyleSheet, Text, TextInput,Pressable,View,TouchableOpacity } from 'react-native';
import { Button } from "@react-native-material/core";


export default function SearchBar({ onSearch,onClear,tag }) {

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    // Call the onSearch callback with the current search term
    onSearch(searchTerm);
    setSearchTerm(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.nativeEvent.key === 'Enter') {
      // If the "Enter" key is pressed, initiate the search
      handleSearch();
    }
  };


  const handleClear = () => {
    setSearchTerm('');
    onClear(); // Call the onClear callback from the parent component
  };

  return (
    <View style={styles.filterContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search"
          onChangeText={setSearchTerm}
          value={searchTerm}
          placeholderTextColor="#9b9a9a"
          onKeyPress={handleKeyPress}
          clearButtonMode="unless-editing" // Show clear button while editing
        />
      {searchTerm.length > 0  && (
        <TouchableOpacity onPress={handleClear} style={styles.clearButton}>
          <Text style={styles.clearButtonText}>Clear</Text>
        </TouchableOpacity>
      )}
        <TouchableOpacity>
          <Button style={styles.submitbtn} title="Search" onPress={handleSearch}/>
        </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  SearchBar: {
    margin: 2,
    padding: 10,
    flex: 1,
    borderRadius: 18,
    borderColor:"#9d9d9d",
    backgroundColor:'#fff'
  },
  filterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    width:"100%"
  },
  headerContainer:{
    width:'100%',
    padding: 10,
    flexDirection:"row",
  },
  submitbtn:{
    margin: 2,
    marginLeft:5,
    backgroundColor:"#2196f3",
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.,
    shadowRadius: 4,
  },
  clearButton: {
    marginLeft: 5,
    border: "1px #979797 solid",
    borderRadius: 18,
  },
  clearButtonText: {
    color: '#000',
    padding:10
  },
  searchInput: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
    border: "1px #979797 solid",
    borderRadius: 18,
    backgroundColor: '#fff',
    fontSize: 16, // Adjust font size
    maxWidth: '80%', // Responsive width
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
});