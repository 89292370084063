import axios from "axios";

//## Get Notifications by User ID
//Request: `GET /n/notification/message/<user_id>
export const getNotificationByUserId = async (user_id,jwtToken) => {
  console.log('process: ', process.env.BASE_API_Domain);
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_API_Domain || " http://localhost:3000/"
		}/n/notification/message/${user_id}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
  try {
    const { data } = await axios(config);
    return data
  } catch (error) {
    console.error("getNotificationByUserId", error);
  }
};

//## Removed Notification
//n/notification/message/<user_id>/<notification_id>
/*
{
    "action": "READ"
}
*/
export const removeNotification = async (user_id,notification_id,body,jwtToken) => {
  console.log('process: ', process.env.BASE_API_Domain);
  const config = {
    method: "POST",
    url: `${
      process.env.BASE_API_Domain || " http://localhost:3000/"
    }/n/notification/message/${user_id}/${notification_id}`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
    data: { ...body},

  }; 
  try {
    const { data } = await axios(config);
    return data
  } catch (error) {
    console.error("removeNotification", error);
  }
};





