import React, { useState,useRef } from "react";
import { View, Modal, Pressable, Text, StyleSheet,ScrollView } from "react-native";

export default function FilterModal({ message, visible, onClose }) {

const [modalVisible, setModalVisible] = useState(visible);

  // When the visible prop changes, update the modal's visibility
  React.useEffect(() => {
    setModalVisible(visible);

  }, [visible]);


return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <Pressable
          style={styles.overlay}
          onPress={onClose}
        >
        </Pressable>
        <View style={styles.modalView}>
          <Pressable
            style={[styles.button, styles.buttonClose]}
            onPress={onClose}
          >
            <Text style={styles.textStyle}>Close</Text>
          </Pressable>
        	<ScrollView>
            	<Text>testing</Text>
            </ScrollView>
        </View>
      </View>
    </Modal>
  );

}

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: "#6e6e6e85",
    height: "100%",
    justifyContent:"end"
  },
  overlay:{
    height: "100%",
    opacity: 0.1,
    elevation: 2,
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: "80%",
  },
  button: {
    borderRadius: 20,
    padding: 5,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2185d0",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
  },
  textArea:{
    height: "3em",
  }
});