import React, { useState,useRef } from "react";
import { View, Modal, Pressable, Text, StyleSheet,ScrollView,FlatList } from "react-native";
import 'semantic-ui-css/semantic.min.css';
import { Button, Comment, Form, Header } from 'semantic-ui-react';
import {createMessage,listMessage,replyMessage} from '../trpc/message';
import  GeneralHelper  from './utils/GeneralHelper';
import MemberHelper from './utils/MemberHelper';
import PopUpDialog from './PopUpDialog';
import { useNavigation } from '@react-navigation/native';

export default function CommentModal({ message, visible, onClose,data }) {
  const [modalVisible, setModalVisible] = useState(visible);
  const [replyContent, setReplyContent] = useState("");
  const [subReplyContent, setSubReplyContent] = useState("");
  const [submitResult, setSubmitResult] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showSubCommentInput, setShowSubCommentInput] = useState({});
  const newCommentRef = useRef(null);
  const token = localStorage.getItem("token");
  const [isMember, setIsMember] = useState(false);
  const [popupMsg, setPopUpMsg] = useState("");
  const [popUpVisible, setPopupVisible] = useState(false);
  const navigation = useNavigation();

  // When the visible prop changes, update the modal's visibility
  React.useEffect(() => {
    setModalVisible(visible);

    //get message list
    updateCommentList();
  }, [visible]);

  const updateCommentList = ()=>{

    let bodyobj = { 
                      "topicId": data[0].topics[0].id, 
                      "createdAscending": false, 
                      "skip": 0 
                  };
    //listMessage
    listMessage(bodyobj,token).then(result => {
        setCommentList(result);
        console.log(result);
    })
    .catch(error => {
        console.log(error);

    });

  }

  const checkMember = () =>{
      if(MemberHelper.checkMemberStatus()){
        setIsMember(true);
      }else{
        setPopUpMsg("You are not member");
        setPopupVisible(true);
        return;
      }
  }

  const handleReplySubmit = () => {
    //console.log(data[0].topics[0].id);

    checkMember();

    if(replyContent!=""&&!isSubmit){

      setIsSubmit(true);

        let bodyobj = {
              "content": replyContent,
              "topicId": data[0].topics[0].id,
            };

        createMessage(bodyobj,token).then(result => {
            console.log(result);
            setSubmitResult(result);
            updateCommentList();
            focusNewComment();
            resetReplyInput();
            setIsSubmit(false);
        })
        .catch(error => {
            console.log(error);
            setIsSubmit(false);

        });

      console.log("submitted");
    }
  };

  const resetReplyInput = () =>{

    setReplyContent("");
    setSubReplyContent("");
    setShowSubCommentInput("");
  }

  const handleReplyInput = (evt) => {
      setReplyContent(evt.target.value);
    //console.log(evt.target.value);
  };

  const handleSubCommentInput = (evt, parentMsgID) => {
    console.log("handleSubCommentInput",parentMsgID);
    setSubReplyContent(evt.target.value);
  };

  const handleSubCommentSubmit = (parentMsgID)  =>  {

    console.log("handleSubCommentSubmit");

    if(subReplyContent!=""&&!isSubmit){

        setIsSubmit(true);

        let bodyobj = {
              "content": subReplyContent,
              "topicId": data[0].topics[0].id,
              "parentId": parentMsgID
            };

        replyMessage(bodyobj,token).then(result => {
            console.log(result);
            setSubmitResult(result);
            updateCommentList();
            resetReplyInput();
            setIsSubmit(false);
        })
        .catch(error => {
            console.log(error);
            setIsSubmit(false);

        });

      console.log("submitted");
    }


  }

  const replyToParent = (parentMsgID) => {
    console.log(parentMsgID);
    setShowSubCommentInput((prevState) => ({
      ...prevState,
      [parentMsgID]: true,
    }));
  };

  // Render each object as a component
  const renderCommentItem = ({ item }) => (
      <Comment>
        <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
        <Comment.Content>
          <Comment.Author as='a'>Matt</Comment.Author>
          <Comment.Metadata>
            <div>{formatDateTime(item.createdAt)}</div>
          </Comment.Metadata>
          <Comment.Text><p>{item.content}</p></Comment.Text>
          <Comment.Actions>
            <Comment.Action>Reply</Comment.Action>
          </Comment.Actions>
        </Comment.Content>
      </Comment>
  );

  // Function to focus the new comment
  const focusNewComment = () => {
    if (newCommentRef.current) {
      newCommentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  // Function to show the popup
  const showModal = () => {
    setPopupVisible(true);
  };
  const closeModal = () => {
    setPopupVisible(false);
    navigation.navigate('profile', { screen: 'ProfileScreen' });
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
          <Pressable
            style={styles.overlay}
            onPress={onClose}
          >
          </Pressable>
          <PopUpDialog
            message={popupMsg}
            visible={popUpVisible}
            onClose={closeModal}
          />
        <View style={styles.modalView}>
          <Pressable
            style={[styles.button, styles.buttonClose]}
            onPress={onClose}
          >
            <Text style={styles.textStyle}>X</Text>
          </Pressable>
            <ScrollView style={styles.scrollView}>
                <Comment.Group>
                      <Header as='h3' dividing>
                          Comments
                      </Header>
                      <Form reply>
                        <Form.TextArea style={styles.textArea} onChange={handleReplyInput} value={replyContent} />
                        <Pressable onPress={handleReplySubmit}>
                          <Button content='Add Reply' labelPosition='left' icon='edit' primary />
                        </Pressable>
                      </Form>
                      {commentList.map((item, index) => (
                          <Comment key={item.id}>
                            <p ref={index == 0 ? newCommentRef : null} ></p>
                            <Comment.Avatar src={GeneralHelper.deviceToPlatForm(item.owner.profileURL)} />
                            <Comment.Content>
                              <Comment.Author as='a'>{item.owner.name}</Comment.Author>
                              <Comment.Metadata>
                                <div>{formatDateTime(item.createdAt)}</div>
                              </Comment.Metadata>
                              <Comment.Text><p>{item.content}</p></Comment.Text>
                                <Comment.Actions style={styles.replyBox}>
                                  <Comment.Action onClick={()=>replyToParent(item.id)} >Reply</Comment.Action>
                                    {showSubCommentInput[item.id] && (
                                      <React.Fragment>
                                        <Form reply>
                                        <Form.TextArea
                                          style={styles.subTextArea}
                                          onChange={(evt) => handleSubCommentInput(evt, item.id)}
                                          placeholder={"Reply to "+item.owner.name}
                                          value={subReplyContent}
                                        />
                                        <Pressable onPress={() => handleSubCommentSubmit(item.id)}>
                                          <Button content="Reply" labelPosition="left" icon="edit" secondary />
                                        </Pressable>
                                        </Form>
                                      </React.Fragment>
                                    )}
                                </Comment.Actions>

                                    {item.children.map((childComment, index) => (
                                      <Comment key={childComment.id}>
                                        <Comment.Avatar src={GeneralHelper.deviceToPlatForm(childComment.owner.profileURL)} />
                                        <Comment.Content>
                                          <Comment.Author as='a'>{childComment.owner.name}</Comment.Author>
                                          <Comment.Metadata>
                                            <div>{formatDateTime(childComment.createdAt)}</div>
                                          </Comment.Metadata>
                                          <Comment.Text> {childComment.content}</Comment.Text>
                                          <Comment.Actions style={styles.replyBox}>
                                            
                                              {showSubCommentInput[childComment.id] && (
                                                <React.Fragment>
                                                  <Form reply>
                                                    <Form.TextArea
                                                    style={styles.subTextArea}
                                                    onChange={(evt) => handleSubCommentInput(evt, item.id)}
                                                    placeholder={"Reply to "+item.owner.name}
                                                    value={subReplyContent}
                                                    />
                                                    <Pressable onPress={() => handleSubCommentSubmit(item.id)}>
                                                      <Button content="Reply" labelPosition="left" icon="edit" secondary />
                                                    </Pressable>
                                                  </Form>
                                                </React.Fragment>
                                              )}
                                          </Comment.Actions>
                                        </Comment.Content>
                                      </Comment>
                                    ))}
                            </Comment.Content>
                          </Comment>
                      ))}
                  </Comment.Group>
            </ScrollView>
        </View>
      </View>
    </Modal>
  );
}

  const formatDateTime = (dateTimeString)=> {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    const isToday = dateTime.toDateString() === now.toDateString();
    const isYesterday = dateTime.toDateString() === yesterday.toDateString();

    if (isToday) {
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `Today at ${formattedHours}:${formattedMinutes}${amOrPm}`;
    } else if (isYesterday) {
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `Yesterday at ${formattedHours}:${formattedMinutes}${amOrPm}`;
    } else {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate = dateTime.toLocaleDateString(undefined, options);
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedDate} at ${formattedHours}:${formattedMinutes}${amOrPm}`;
    }
  }

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: "#6e6e6e85",
    height: "100%",
    justifyContent:"end"
  },
  scrollView:{
    maxWidth:'320px',
    minWidth:'320px'
  },
  overlay:{
    height: "100%",
    opacity: 0.1,
    elevation: 2,
  },
  replyBox:{
    width:"85%"
  },
  subTextArea:{
    padding:10,
    height:"3em"
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: "80%",
  },
  button: {
    borderRadius: 20,
    padding: 5,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#000",
    alignSelf: "end",
    width: 27,
  },
  textStyle: {
    color: "white",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
  },
  textArea:{
    height: "3em",
  }
});
