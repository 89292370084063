// HomeScreen.js
import React,{useEffect,useState,useCallback} from "react";
import { StatusBar } from 'expo-status-bar';
import { View,SafeAreaView,ScrollView,TextInput, TouchableOpacity,Text,StyleSheet,} from "react-native";
import { useNavigation,useRoute,useFocusEffect } from '@react-navigation/native';
import GetMemoList from "../components/GetMemoList";
import SearchBar from "../components/SearchBar";
import { Button} from "@react-native-material/core";
import Ionicons from 'react-native-vector-icons/Ionicons';
import FilterModal from "./FilterModal";

export default function HomeScreen({ navigation }) {

  const [modalVisible, setModalVisible] = useState(false);
  const [keyPhrase, setkeyPhrase] = useState("");
  const [tag, setTag] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const route = useRoute();

  useEffect(() => {

    if (route.params?.tag) {
        setkeyPhrase(route.params?.tag);
        setTag(route.params?.tag);
    }
  }, [route.params?.tag]);

  useFocusEffect(
    useCallback(() => {
      console.log("Component gained focus");

    }, [navigation]) // The dependencies array should be empty if you don't have any external dependencies
  );

  const openModal = () =>{

    setModalVisible(true);
    console.log("Clicked");

  }

  const closeModal = () => {
    setModalVisible(false);
  };

  const search = (searchTerm) => {
    console.log(searchTerm);
    // Perform search logic or update state based on the search term
    setkeyPhrase(searchTerm);
  };

  const handleClear = () => {
    setkeyPhrase('');
    setTag('');
    navigation.setParams({
      tag: '',
    });
  };

  return (
      <View style={styles.container}>
        <SearchBar onSearch={search} onClear={handleClear} tag={keyPhrase} />
        {tag.length >0 && (
            <Text> 
              <Ionicons name="pricetag-outline" />
              {tag+" "}
              <TouchableOpacity
                onPress={handleClear}
              >
                <Ionicons name="close-circle-outline"></Ionicons>
              </TouchableOpacity>
            </Text>          
        )}

        <GetMemoList keyPhrase={keyPhrase}/>

        <FilterModal visible={modalVisible} onClose={closeModal} />
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  footerContainer: {
    flex: 1 / 3,
    alignItems: 'center',
  },
  filterBtnOuter:{
    borderRadius:28,
    //width:"20%",
  },
  scrollView:{
    width:'100%'
  },
  searchBar: {
    with:"100%"
  },
  submitbtn:{
    margin: 2,
    marginLeft:5,
    backgroundColor:"#2196f3",
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.,
    shadowRadius: 4,
  },
  tagStyle:{
    backgroundColor:"#000",
    color:"#fff"
  },
  tagText:{
    padding:20
  },
  searchInput: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
    border: "1px #979797 solid",
    borderRadius: 18,
    backgroundColor: '#fff',
    fontSize: 16, // Adjust font size
    maxWidth: '80%', // Responsive width
  },
});