import React from "react";
import { View,Text,SafeAreaView,StyleSheet } from "react-native";


export default function NotFoundScreen() {

  return (
    <SafeAreaView>
      <View style={styles.container}>
        <Text style={styles.text}>404 Not Found</Text>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 20,
  },
});