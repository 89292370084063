import axios from "axios";

export const socialSignIn = async (body, type = 'GOOGLE') => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const config = {
		method: "POST",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/socialSignIn`,
		headers: { Accept: "application/json" },
		data: { ...body, type },
	};
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("social sign in", error);
  }
};

export const getUser = async (uid, jwtToken) => {
  const payload = { "id": uid };
  const config = {
		method: "GET",
		url: `${
			process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
		}/getUser?input=${encodeURIComponent(JSON.stringify(payload))}`,
		headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
	};
	try {
		const { data } = await axios(config);
		return data.result.data;
	} catch (error) {
		console.error("get user by token and id", error);
	}
}

export const updateUserInfo = async (obj, jwtToken) => {
  console.log('process: ', process.env.BASE_TRPC_URL);
  const payload = obj;
  const config = {
    method: "POST",
    url: `${
      process.env.BASE_TRPC_URL || " http://localhost:3000/trpc"
    }/updateUserInfo`,
    headers: { Accept: "application/json", Authorization: `Bearer ${jwtToken}` },
    data: { ...payload},

  };
  try {
    const { data } = await axios(config);
    return data.result.data
  } catch (error) {
    console.error("updateUserInfo", error);
  }
};
