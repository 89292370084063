import React, { useState,useEffect,useRef } from "react";
import { View, Modal, Pressable, Text, StyleSheet,ScrollView } from "react-native";
import { ListItem,Avatar } from "@react-native-material/core";
import  GeneralHelper  from './utils/GeneralHelper';
import { getNotificationByUserId,removeNotification } from '../trpc/notification';
import { useNavigation } from '@react-navigation/native';
import { getTopicByTopicId } from '../trpc/memo';

export default function NotificationCenterModal({ data,message, visible, onClose }) {

  const [modalVisible, setModalVisible] = useState(visible);
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("uid");
  const [ returnData, setReturnData ] = useState([{}]);
  const [ topicData, setTopicData ] = useState([{}]);

  const navigation = useNavigation();

  // When the visible prop changes, update the modal's visibility
  useEffect(() => {
    setModalVisible(visible);

  }, [visible]);

  const handleNotiOnClick = (topic_id) =>{

    console.log(topic_id);

    getTopicByTopicId(topic_id,token).then(result =>  {
      setTopicData(result);
      console.log(result);
      let itemID = result.channelId;

      setModalVisible(!visible);
      navigation.navigate('MemoDetailController', {
        itemID,
      });

    })
    .catch(error => {
            console.log(error);
    });

  }


return (
    <Modal
      animationType="slide"
      transparent={true}
      closeTimeoutMS={500}
      visible={modalVisible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
          <Pressable
            style={styles.overlay}
            onPress={onClose}
          >
          </Pressable>
        <View style={styles.modalView}>
          <Pressable
            style={[styles.button, styles.buttonClose]}
            onPress={onClose}
          >
            <Text style={styles.textStyle}>X</Text>
          </Pressable>
            <Text style={styles.activityTextStyle} >Activity</Text>
        	<ScrollView style={styles.notificationListStyle}>
            {
              data&&data.data.map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                          leadingMode="avatar"
                          leading={
                                <Avatar image={{ uri: GeneralHelper.deviceToPlatForm(item.replyer_avatar) }} />
                          } 
                          title={
                              <>
                                  {item.replyer_name} <Text style={styles.systemTextStyle}> comment on your post</Text>
                              </>
                          }
                          onPress={() => handleNotiOnClick(item.topic_id)}
                          secondaryText={GeneralHelper.shortenText(item.content)+"  "+GeneralHelper.formatTimeAgo(item.created_time)}
                      />
                    </React.Fragment>

                 )
            )}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );

}

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: "#6e6e6e85",
    height: "100%",
    justifyContent:"end"
  },
  activityTextStyle:{
    fontWeight: "bold",
  },
  systemTextStyle:{
    color:"grey",
    fontSize:13
  },
  overlay:{
    height: "100%",
    opacity: 0.1,
    elevation: 2,
  },
  notificationListStyle:{
    padding:5,
    width:"100%"
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: "80%",
  },
  button: {
    borderRadius: 20,
    padding: 5,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#000",
    alignSelf: "end",
    width: 27,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
  },
  textArea:{
    height: "3em",
  }
});