  import React, {useEffect,useState,useRef} from "react";
  import {
    View,
    Modal,
    Pressable, 
    Text, 
    SafeAreaView, 
    StatusBar, 
    Dimensions, 
    StyleSheet, 
    ScrollView, 
    Image,
    ActivityIndicator
  } from 'react-native';
  const {width} = Dimensions.get('window');
  import axios from "axios";

  import ReactQuill,{Quill} from 'react-quill'
  import 'quill/dist/quill.snow.css'
  import '../assets/css/styles.css'
  import { Button,Stack,Snackbar, TextInput,Divider } from "@react-native-material/core";
  import SelectDropdown from 'react-native-select-dropdown';

  import FontAwesome from 'react-native-vector-icons/FontAwesome';
  import Ionicons from 'react-native-vector-icons/Ionicons';

  import { createChannel,updateChannel,updateTopic } from '../trpc/memo';
  import { updateAcceptanceList } from '../trpc/participation';
  import  GeneralHelper  from './utils/GeneralHelper';
  import { useNavigation } from '@react-navigation/native';
  import TagInputComponent from './TagInputComponent';
  import loadingImage from '../assets/loadingImage.gif';
  import PopUpDialog from './PopUpDialog';

  let   quillObj = "";
  let   isUploading = false;

  export default function TextEditor({is_edit,memObj}) {

    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageDropDown, setErrorMessageDropDown] = useState("");
    const [errorMessageContent, setErrorMessageContent] = useState("");
    const [errorMessageNumber, setErrorMessageNumber] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [apiMsg, setApiMsg] = useState("");
    const [participationMax, setParticipationMax] = useState(0);
    const [createChannelResult, setCreateChannelResult] = useState("");

    const [title, setTitle] = useState("");
    const [tags, setTags] = useState([]);
    const [content, setContent] = useState("");
    const [cat, setCat] = useState("");
    const [loading, setLoading] = useState(false);

    const titleRef = useRef(null);
    const tagRef = useRef(null);
    const catRef = useRef(null);
    const contentRef = useRef(null);
    const navigation = useNavigation();
    const quillRef = useRef();
    const participantInputRef = useRef();

    const memoCatArray = [
          'INDOOR',
          'OUTDOOR',
          'SHARING',
      ];
    const memoCatDisplay = [
        'Indoor Activity',
        'Outdoor Activity',
        'Sharing',
    ];


    const formats = [
      "header", "height", "bold", "italic",
      "underline", "strike", "blockquote",
      "list", "color", "bullet", "indent",
      "link", "image", "align", "size",
    ];

    const handleProcedureContentChange = (c) => {
      setContent(c);
    };

    const handleTitleInput = (evt) => {
      setTitle(evt.target.value);
      //title = evt.target.value;
    };

    const handleTagInput=(evt)=>{
      console.log(evt);
      setTag(evt.target.value);
    };

    const token = localStorage.getItem("token");

    const handleSubmit = () => {

      console.log("isUploading",isUploading);

      if(isSubmit||isUploading){
        console.log("Block Submit");
        return;
      }

      setIsSubmit(true);
      
      //validation
      (title == "")? setErrorMessageTitle("*Missing Title"):setErrorMessageTitle("");

      (cat == "")? setErrorMessageDropDown("*Missing Category"):setErrorMessageDropDown("");

      (content == "")? setErrorMessageContent("*Missing Content"):setErrorMessageContent("");

      let condition = true;
      if(cat!="SHARING"){

        if(participationMax==0){
          setErrorMessageNumber("*Participation Number cannot be 0")
          condition=false;
        }else if (participationMax == ""){
          setErrorMessageNumber("*Missing participation Number")
          condition=false;
        }else{
          setErrorMessageNumber("");
        }
      }


      //console.log(content);
      console.log(content,title,cat);

      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      let dateTime = date+' '+time;

      let tmpContent = "";
          tmpContent = GeneralHelper.platformToDevice(content);


      if(content!=""&&title!=""&&cat!=""&&condition){

        if (is_edit) {

          let updateChannelBodyobj = {
              "name": title,
              "published": true,
              "category": cat,
              "id":memObj.id,
            };


          updateChannel(updateChannelBodyobj,token).then(result => {

              console.log(content,title,cat);
              console.log(result);
              setCreateChannelResult(result);
              
              let updateTopicBodyobj = {
                  "id": result.topics[0].id,    //topic id
                  "name": title,
                  "description": tmpContent,
                  "tags": tags,
                  "startDateTime": dateTime,
                  "endDateTime": dateTime,
                  "maxCapacityForParticipants":participationMax
                };

              updateTopic(updateTopicBodyobj,token).then(result => {

                //To Do: update Participants
                  console.log(result);
                  setApiMsg("Published Successfully");
                  setModalVisible(!modalVisible);

              })
              .catch(error => {
                  console.log(error);
                  setApiMsg("System Error");
                  setModalVisible(!modalVisible);
              });

          })
          .catch(error => {
              console.log(error);
              setApiMsg("System Error");
              setModalVisible(!modalVisible);
          });


        }else{

          let bodyobj = {
              "name": title,
              "published": true,
              "category": cat,
              "tags": tags,
              "topic":{
                "name": title,
                "tags": tags,
                "description": tmpContent,
                "startDateTime": dateTime,
                "endDateTime": dateTime
              },
              "maxCapacityForParticipants": participationMax,
            };

          //let bodyJson  = JSON.stringify(bodyobj);

          createChannel(bodyobj,token).then(result => {
            console.log(result);
            if(typeof result==="undefined"){
              setApiMsg("Internal Server Error");
              setModalVisible(!modalVisible);
              setIsSubmit(false);
            }else{

              setCreateChannelResult(result);
              let bodyobj = {
                  "participationListId": result.topics[0].participationList.id,
                  "action": "ACCEPT"
                };

                //Default Signup
                updateAcceptanceList(bodyobj,token).then(result => {
                  console.log(result);
                  setApiMsg("Published Successfully");
                  setModalVisible(!modalVisible);
                  setIsSubmit(false);

                }).catch(error => {
                  console.log(error);
                  setApiMsg("System Error");
                  setModalVisible(!modalVisible);
                  setIsSubmit(false);
                });
            }
          })
          .catch(error => {
              console.log(error);
              setApiMsg("System Error");
              setModalVisible(!modalVisible);
              setIsSubmit(false);
          });
        }

      }else{
        console.log("error");
        setIsSubmit(false);
      }

    };

    const handleModalClose = () => {
      
        setModalVisible(!modalVisible);
        console.log(createChannelResult);
        let user_id = createChannelResult.ownerId;

        //clear input
        titleRef.current.value = "";
        catRef.current.reset();

        setTitle("");
        setCat("");
        setContent("");
        setTags([]);
        setParticipationMax(0);

        let itemID = createChannelResult.id;
        let item = createChannelResult;

        //navigation.navigate('Home', { screen: 'mypost' });
          navigation.navigate('MemoDetailController', {
            itemID,
            item,
          });

    }

    const handleNumericInputChange = (text) => {
      // Remove non-numeric characters from the input
      const numericValue = text.replace(/[^0-9]/g, '');
      setParticipationMax(Math.floor(numericValue));
    };


    const preSetEditModeValue = () => {
      setTitle(memObj.name);
      console.log(memObj);
      
      // Check if memObj and memObj.topics are defined before accessing their properties
      if (memObj && memObj.topics && memObj.topics.length > 0) {

        let tmpContent = GeneralHelper.deviceToPlatForm(memObj.topics[0].description);
        setContent(tmpContent);

        //Set Max participant
        setParticipationMax(memObj.topics[0].participationList.maxCapacity);

      } else {
        setContent(""); // Set a default value if memObj or memObj.topics is undefined or empty
      }
      
      // Set the cat value based on memObj.category
      setCat(memObj.category);

      if(memObj.tags){
        setTags(memObj.tags);
      }

    };


    useEffect(() => {

      if (is_edit) {
        console.log(memObj);
        preSetEditModeValue();

      }

    }, [is_edit]);


    return (

      <View style={styles.editorContainer} >
          <ScrollView style={styles.scrollView}>

            <div style={styles.editor}>
                {errorMessageTitle && <div style={styles.errorMsg}> <div>{errorMessageTitle}</div> </div>}
                <TextInput  ref={titleRef} style={styles.titleInput} placeholder="Title" value={title} onChange={handleTitleInput} />

                <Divider style={styles.divider} />

                {errorMessageDropDown && <div style={styles.errorMsg}> <div>{errorMessageDropDown}</div> </div>}
                <SelectDropdown ref={catRef}
                  data={memoCatDisplay}
                  onSelect={(selectedItem, index) => {
                    setCat(memoCatArray[index]);
                  }}
                  defaultButtonText={(is_edit)?memoCatDisplay[memoCatArray.indexOf(memObj.category)]:'Select Category'}

                  buttonTextAfterSelection={(selectedItem, index) => {
                    // text represented after item is selected
                    // if data array is an array of objects then return selectedItem.property to render after item is selected
                    return selectedItem
                  }}
                  rowTextForSelection={(item, index) => {
                    // text represented for each item in dropdown
                    // if data array is an array of objects then return item.property to represent item in dropdown
                    return item
                  }}
                  //value={memoCatDisplay[memoCatArray.indexOf(memObj.category)]}

                    buttonStyle={styles.dropdown1BtnStyle}
                    buttonTextStyle={styles.dropdown1BtnTxtStyle}
                    renderDropdownIcon={isOpened => {
                      return <FontAwesome name={isOpened ? 'chevron-up' : 'chevron-down'} color={'#444'} size={18} />;
                    }}
                    dropdownIconPosition={'right'}
                    dropdownStyle={styles.dropdown1DropdownStyle}
                    rowStyle={styles.dropdown1RowStyle}
                    rowTextStyle={styles.dropdown1RowTxtStyle}
                />

                {errorMessageNumber && cat !== "SHARING"&& cat !== "" && <div style={styles.errorMsg}> <div>{errorMessageNumber}</div> </div>}
                {
                  cat=="INDOOR" || cat=="OUTDOOR"?(
                  <TextInput
                    ref={participantInputRef}
                    style={styles.numericInput}
                    keyboardType="numeric"
                    placeholder="Enter number of participants"
                    value={participationMax}
                    onChangeText={handleNumericInputChange}
                  />):null
                }

                <TagInputComponent 
                    returnTags={tags}
                    updateTags={setTags}
                />

                <Divider style={styles.divider} />

                {errorMessageContent && <div style={styles.errorMsg}> <div>{errorMessageContent}</div> </div>}

                <ReactQuill  
                  ref={(ef)=>{
                    quillObj=ef;
                  }}   
                  modules={{
                      toolbar: {
                        container:[
                          [{ size: ["small", false, "large", "huge"] }],
                          ["bold", "italic", "underline", "strike","image","video"],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                            { align: [] }
                          ],
                          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
                        ],
                        handlers: {  
                          "image": imageHandler
                        } 
                      }
                  }}  
                  value={content}
                  style={styles.textEditor}
                  placeholder="write your content ...."
                  onChange={handleProcedureContentChange}
                  id="txtDescription"  
                /> 

            </div>
            <PopUpDialog
              message={apiMsg}
              visible={modalVisible}
              onClose={handleModalClose}
            />

          </ScrollView>
          {loading === true && (
            <View style={styles.loadingScreen}>
                <ActivityIndicator animating={loading} size="large" />
            </View>
          )}
              <View style={styles.submitButtonContainer}>
                <Button title={(is_edit) ? "Modify" : "Submit"} onPress={handleSubmit} />
              </View>
      </View>
    );

  }
    const imageHandler = async ()=> { 
        const input = document.createElement('input');  
      
        input.setAttribute('type', 'file');  
        input.setAttribute('accept', 'image/*');  
        input.click();  
      
        input.onchange = async () => {  
          var file: any = input.files[0];  
          var formData = new FormData(); 
          isUploading = true; 
      
          formData.append('images', file);
          console.log(file);
      
          var fileName = file.name;  
      
          const res = await uploadFiles(formData,file, fileName);  
        };  
    }

    const uploadFiles = async (formData,uploadFileObj, filename)=> {  
        console.log(uploadFileObj,filename,quillObj);
        let libraryName = "ImageFiles";  
        const range = quillObj.getEditorSelection(); 
        const token = localStorage.getItem("token");
        isUploading = true;

    
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: process.env.BASE_IMAGE_UPLOAD_URL,
          headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'multipart/form-data',
          },
          data : formData
        };
        console.log(range.index);
        console.log("isUploading",isUploading);

        quillObj.getEditor().insertEmbed(range.index, 'image', loadingImage);
        quillObj.getEditor().enable(false);

        axios.request(config)
        .then((response) => {
          console.log(response);
          console.log(JSON.stringify(response.data));
          isUploading = false;
          quillObj.getEditor().deleteText(range.index, 1);
          quillObj.getEditor().insertEmbed(range.index, 'image', GeneralHelper.deviceToPlatForm(response.data.data[0]));
          quillObj.getEditor().enable(true);
        })
        .catch((error) => {
          quillObj.getEditor().deleteText(range.index, 1);
          quillObj.getEditor().enable(true);
          isUploading = false;
          console.log(error);
        });  
    }

  const styles = StyleSheet.create({
    headTitle: {
      alignSelf: "center", 
      color:"rgb(33, 33, 33)",
      fontSize: 16,
      paddingLeft:16,
      paddingRight:16,
      paddingTop:18
    },
    titleInput:{
      
    },
    tagInput:{
      
    },
    loadingScreen:{
      backgroundColor: "#000",
      flex:1,
      opacity: 0.2,
      position: "absolute",
      zIndex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    editorContainer:{
      flex:1,
      minHeight:500
    },
    tagContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    systemMsg:{
      position: "absolute", 
      start: 16, 
      end: 16, 
      bottom: 16
    },
    editor:{
      justifyContent: "center",
      padding:15
    },
    submitbtn: {
      alignSelf: "center", 
      marginTop: 80,
      backgroundColor:"#2196f3",
    },
    divider:{
      marginTop: 20,
      marginBottom:20,
    },
    dropdown1BtnStyle: {
      width: '80%',
      height: 50,
      backgroundColor: '#FFF',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#cccccc',
    },
    dropdown1BtnTxtStyle: {
      color: '#999999', 
      textAlign: 'left',
      fontSize: 16,
    },
    dropdown1DropdownStyle: {backgroundColor: '#EFEFEF'},
    dropdown1RowStyle: {backgroundColor: '#EFEFEF', borderBottomColor: '#C5C5C5'},
    dropdown1RowTxtStyle: {color: '#444', textAlign: 'left'},
    scrollView:{
    
    },
    errorMsg:{
      color:"red",
      marginLeft:5,
      fontSize:12,
    },
    centeredView: {
      backgroundColor: "#6e6e6e85",
      height: "100%",
    },
    modalView: {
      margin: 20,
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: '#F194FF',
    },
    buttonClose: {
      backgroundColor: '#2196F3',
    },
    textStyle: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    modalText: {
      marginBottom: 15,
      textAlign: 'center',
    },
    textEditor:{
      //height:200
    },
    numericInput:{
      paddingTop:5,
      paddingLeft:15,
      paddingRight:15,
    },
    submitButtonContainer: {
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "transparent", // Set your preferred background color
      padding: 10, // Adjust the padding as needed
    },
  });

