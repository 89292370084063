import React, { useState } from "react";
import { View, Modal, Pressable, Text, StyleSheet } from "react-native";
import { Snackbar } from "@react-native-material/core";


export default function SocialSharingModal({ message, visible, callback }) {
  const [modalVisible, setModalVisible] = useState(visible);
  const [systemMsg, setSystemMsg] = useState("");


  // When the visible prop changes, update the modal's visibility
  React.useEffect(() => {
    setModalVisible(visible);
  }, [visible]);


  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalText}>{message}</Text>
          {/* Copy Link Button */}
          <Pressable
            style={[styles.button, styles.buttonCopy]}
            onPress={callback}
          >
            <Text style={styles.textStyle}>Copy Link</Text>
          </Pressable>

        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: "#6e6e6e85",
    height: "100%",

  },
  snackbarContainer: {
    //backgroundColor: '#2196f3', // Set your desired background color
    //borderRadius: 8, // Adjust border radius
  },
  modalView: {
    margin: 35,
    backgroundColor: "white",
    borderRadius: 0,
    padding: 0,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    top: "30%",
  },
  button: {
    borderRadius: 0,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  buttonCopy: {
    backgroundColor: "#000",
    width:"100%",
    padding:15
  }, 
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    margin: 15,
    textAlign: "center",
  },
  linkTextStyle:{
    color:"black",
    paddingBottom:10
  }
});
