import React, { useState,useEffect } from "react";
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,Button,TouchableOpacity,Modal } from 'react-native';
import SearchBar from './components/SearchBar';
import MemoList from './components/MemoList';
import { Ionicons } from '@expo/vector-icons';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

import { NavigationContainer,useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import MemberHelper from './components/utils/MemberHelper';
import PopUpDialog from './components/PopUpDialog';


import HomeScreen from "./components/HomeScreen";
import EditMemo from "./components/EditMemo";
import MemoDetailController from "./components/MemoDetailController";
import MyPostScreen from "./components/MyPostScreen"; 
import NewPostScreen from "./components/NewPostScreen";
import ProfileScreen from "./components/ProfileScreen";
import ChatRoomScreen from "./components/ChatRoomScreen";
import NotFoundScreen from "./components/NotFoundScreen";
import NotificationCenterModal from "./components/NotificationCenterModal";
import MasonryList from 'react-native-masonry-list';
import { getNotificationByUserId,removeNotification } from './trpc/notification';

import "./assets/css/styles.css";
import "./assets/css/quill.snow.css";

const MainStack = createNativeStackNavigator();
const TabNavigator = createBottomTabNavigator();


function Home() {

  return (
    <TabNavigator.Navigator
        screenOptions={{
            headerShown: false,
        }}
        initialRouteName="home"
    >
      <TabNavigator.Screen name="home" component={HomeScreen}
        options={{
          title:"",
          tabBarIcon: ({ focused, color, size }) => (
            <Ionicons
              name={focused ? 'home' : 'home-outline'}
              size={size}
              color={color}
            />
          )
        }}
      />
      <TabNavigator.Screen name="mypost" component={MyPostScreen}
        options={{
          title: "",
          tabBarIcon: ({ focused, color, size }) => (
            <Ionicons
              name={focused ? 'albums' : 'albums-outline'}
              size={size}
              color={color}
            />
          )
        }}
      />
      <TabNavigator.Screen name="newpost" component={NewPostScreen}
        options={{
          title: "",
          tabBarIcon: ({ focused, color, size }) => (
            <Ionicons
              name={focused ? 'add-circle' : 'add-circle-outline'}
              size={size}
              color={color}
            />
          )
        }}
      />
      <TabNavigator.Screen name="profile" component={ProfileScreen}
        options={{
          title: "",
          tabBarIcon: ({ focused, color, size }) => (
            <Ionicons
              name={focused ? 'person-circle' : 'person-outline'}
              size={size}
              color={color}
            />
          )
        }}
      />
    </TabNavigator.Navigator>
  );
}

export default function App() {

  const [isModalVisible, setModalVisible] = useState(false);
  const [ notiData, setNotiData ] = useState([{}]);
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("uid");
  const [badgeInvisible, setBadgeInvisible] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [popupMsg, setPopUpMsg] = useState("");
  const [popUpVisible, setPopupVisible] = useState(false);



  useEffect(() => {


      if(MemberHelper.checkMemberStatus()){
        setIsMember(true);
      }else{
        setPopUpMsg("You are not member");
        return;
      }

      getNotificationByUserId(user_id,token).then(result => {
        console.log(result);

        setNotiData(result);
        checkNewNotification(result.data.length,"load");

      }).catch(error => {
        console.log(error);
      });

  }, []);

  const checkNewNotification = (newNumNoti,action) =>{

    const numberOfNotification = localStorage.getItem("NumNoti");

    //First time enter the website
    if(newNumNoti>0 && numberOfNotification==null&&action=="load"){
      //localStorage.setItem("NumNoti",newNumNoti);
      setBadgeInvisible(false);

      
    }else if(newNumNoti>numberOfNotification&&action=="load"){
      setBadgeInvisible(false);

    }


    //action = click
    if (newNumNoti>numberOfNotification && action=="click"){
      localStorage.setItem("NumNoti",newNumNoti);
      setBadgeInvisible(true);
      
    } else if (newNumNoti==numberOfNotification&& action=="click"){
      setBadgeInvisible(true);
      
    }else{
      console.log(newNumNoti,'4');
    }

     return;
  }

  const config = {
    screens: {
      Home: {
        screens: {
          profile: 'profile',
        },
      },
      MemoDetailController:"detail/:itemID/:is_edit",
      ChatRoomScreen:"channel/:memoID",
      MyPostScreen:"mypost/",
      EditMemo:"edit/:is_edit/:memoID",
      NotFoundScreen: "404",
    },
  };

  const linking = {
    prefixes: ['https://teamupla.com', 'teamupla://'],
    config,
  };

  const toggleNotificationModal = ()=>{

    if(MemberHelper.checkMemberStatus()){
      setIsMember(true);
    }else{
      setPopUpMsg("You are not member");
      setIsMember(false);
      setPopupVisible(true);
      return;
    }

   setModalVisible(!isModalVisible);

    if (notiData && notiData.data) {
      checkNewNotification(notiData.data.length, "click");
    } else {
      // Handle the case where notiData or notiData.data is undefined or null
      console.error("Error: notiData or notiData.data is undefined or null");
    }

  }

  // Function to show the modal
  const showModal = () => {
    setPopupVisible(true);
  };
  const closeModal = () => {
    setPopupVisible(false);
    location.href="/profile";
  };

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <MainStack.Navigator>
        <MainStack.Screen name="Home" component={Home} options={{
          title: "Make Vancouver Home",
          headerRight: () => (
            <TouchableOpacity style={styles.notificationBage}>
              <Badge size="large" variant="dot" color="secondary" invisible={badgeInvisible}>
                <MailIcon color="action" size="large" onClick={toggleNotificationModal} />
              </Badge>
            </TouchableOpacity>
          ),
        }}/>
        <MainStack.Screen name="MemoDetailController" component={MemoDetailController}
          options={{
            title: "Memo",
          }}
        />
        <MainStack.Screen name="ChatRoomScreen" component={ChatRoomScreen} options={{title: "ChatRoomScreen"}} />
        <MainStack.Screen name="EditMemo" component={EditMemo} options={{title: "Edit Memo"}} />

        <MainStack.Screen name="NotificationCenterModal" 
              component={NotificationCenterModal} options={{title: "Notification",animation:"slide_from_right"}} />

        <MainStack.Screen name="NotFoundScreen" component={NotFoundScreen} options={{title: "404 Not Found"}} />
      </MainStack.Navigator>

      {notiData&&notiData.data? (
          <NotificationCenterModal data={notiData} visible={isModalVisible} onClose={toggleNotificationModal} />
        ):null
      }
          <PopUpDialog
            message={popupMsg}
            visible={popUpVisible}
            onClose={closeModal}
          />
    </NavigationContainer>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    padding:10,
    justifyContent: 'center',
  },
  notificationBage:{
    paddingRight:20
  },
  headerContainer:{
    width:'100%',
    padding: '10px',
    flexDirection:"row",
    maxWidth:'780px'
  },
  footerContainer: {
    flex: 1 / 3,
    alignItems: 'center',
  },
  searchBarOuter:{
    width:'80%',
  },
  FilterBtnOuter:{
    width:"20%",
  }

});
