import React, { useEffect, useState } from "react";
import { useNavigation, useFocusEffect } from '@react-navigation/native';

const MemberHelper = {
  checkMemberStatus:  () => {
    //const navigation = useNavigation();
    //const [isMember, setIsMember] = useState([]);
    let isMember = false;

          const savedProfile = localStorage.getItem("userProfile");
          const jsonProfile = JSON.parse(savedProfile);

          if (jsonProfile === null) {
            //navigation.navigate("ProfileScreen");
            console.log("not member");
            
          } else {
            console.log("member");
            isMember = true;
            
          }
          
          return isMember;

  },
};

export default MemberHelper;
