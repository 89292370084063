import React, { Component } from "react";
import { Button, View, Text,Image } from "react-native";
import MemberHelper from './utils/MemberHelper';

export default function ChatRoomScreen({ route, navigation }) {

  MemberHelper.checkMemberStatus();

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text>Chat Room Screen</Text>
    </View>
  );
}