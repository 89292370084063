import React, { useState, useEffect,useMemo } from 'react';
import { StyleSheet, Text, TextInput,ScrollView,ActivityIndicator } from 'react-native';
import MemoCard from "../components/MemoCard";
import MemoList from '../components/MemoList';
import { listChannels,listChannelsByUserId } from '../trpc/memo';
import { searchChannel } from '../trpc/search';
import GeneralHelper from './utils/GeneralHelper';

export default function GetMemoList({ user_id, mode,isRefresh,keyPhrase }) {
  const [memdata, setMemdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEndData, setIsEndData] = useState(false);
  const token = localStorage.getItem("token");

  const memoColorArray = [
    '#f9ec8e',
    '#f98eac',
    '#7cd8ff',
    '#beeb97',
    '#ffa371',
    '#d0abfa',
  ];

  console.log("GetMemoList component 1",loading);

  const appendDataWithColor = useMemo(() => {
    return (result) => {
      const dataWithColors = result.map((item) => ({
        ...item,
        backgroundColor:
          memoColorArray[Math.floor(Math.random() * memoColorArray.length)],
      }));
      return dataWithColors;
    };
  }, [memoColorArray]);

  useEffect(() => {

    console.log("GetMemoList component 2",loading);
    if (loading) return;

    if(mode=="mypost"){

      listChannelsByUserId(token,{"uid":user_id,"createdAscending":false})
          .then((result) => {

        let dataWithColors = appendDataWithColor(result);
        setMemdata(dataWithColors);
        setLoading(false);
        console.log(dataWithColors);

      })
      .catch((error) => {
        GeneralHelper.debug(error);
        setLoading(false);
      });
    }else{

      if(keyPhrase!=""){
        console.log("searching");

        let playload={
           "searchType":"match",
           "term":keyPhrase,
           //"category":"<optional>",
           //"from":"<optional>",
           "maxResults":30,
           //"filterType":"<optional>"
        }

        searchChannel(playload,token)
          .then((result) => {

            let dataWithColors = appendDataWithColor(result);
            setMemdata(dataWithColors);
            setLoading(false);
            console.log(dataWithColors);

          })
          .catch((error) => {
            GeneralHelper.debug(error);
            setLoading(false);
          });

      }else{
        console.log("bingo");

        let playload={
           "createdAscending":false
        }

        listChannels(token,playload)
          .then((result) => {

            let dataWithColors = appendDataWithColor(result);

            setMemdata(dataWithColors);
            setLoading(false);
            console.log(dataWithColors);

          })
          .catch((error) => {
            GeneralHelper.debug(error);
            setLoading(false);
          });
      }
    }


  }, [keyPhrase]); // Empty dependency array ensures this effect runs only once

  const handleEndReached = () => {

      console.log("handleEndReached triggered",loading);
      if (loading) {return};

      setLoading(true);

      if(isEndData){
        setLoading(false); 
        return;
      }

      console.log("Nothing should go here if loading is true and is end data",loading);

        const nextSkip = memdata.length; 

        let playload={
           "skip":nextSkip, //dynamic value to get next 10 data
           "maxTake":10,
           "createdAscending":false
        }

        listChannels(token,playload)
          .then((result) => {

            if(result.length==0){
              setIsEndData(true);
            }

            let dataWithColors = appendDataWithColor(result);

            setMemdata((prevData) => [...prevData, ...dataWithColors]);
            setLoading(false);

          })
          .catch((error) => {
            GeneralHelper.debug(error);
            setLoading(false);
          });
  };

  return (
    <>
    <MemoList loading={loading} data={memdata} mode={mode} handleEndReached={handleEndReached}/>
    {loading &&  <ActivityIndicator style={styles.horizontal} animating={loading} size="large" />}
    </>
  );
}


const styles = StyleSheet.create({
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    //paddingTop: 200,
  }
});
