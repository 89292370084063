import React, { useRef, useState,useEffect } from "react";
import { 
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform
} from "react-native";

import TextEditor from "../components/TextEditor";
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import MemberHelper from './utils/MemberHelper';
import PopUpDialog from './PopUpDialog';

export default function NewPostScreen({ route, navigation }) {

  const savedProfile = localStorage.getItem("userProfile");
  const jsonProfile = JSON.parse(savedProfile);
  const [popupMsg, setPopUpMsg] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isMember, setIsMember] = useState(false);

  // Use useFocusEffect to call MemberHelper.checkMemberStatus when the component gains focus
  useFocusEffect(
    React.useCallback(() => {
      console.log("Component gained focus");
      console.log(MemberHelper.checkMemberStatus());

      if(MemberHelper.checkMemberStatus()){
        setIsMember(true);
      }else{
        setPopUpMsg("You are not member");
        setIsMember(false);
        setModalVisible(true);
      }
    }, []) // The dependencies array should be empty if you don't have any external dependencies
  );

  // Function to show the modal
  const showModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
    if(!isMember){
      navigation.navigate("profile");
    }
  };

  return (
    <View style={styles.container}>
        <TextEditor />
          <PopUpDialog
            message={popupMsg}
            visible={modalVisible}
            onClose={closeModal}
          />
    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});